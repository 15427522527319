import React from 'react'
import * as style from "../styles/privacy.module.css"
import {graphql} from "gatsby"
import { PortableText } from "@portabletext/react";

const components = {
    marks: {
        link: ({ value, children }) => {
            const type = () => {
                switch (value.type) {
                    case "email":
                        return (
                            <a className={style.link} href={`mailto:${value.link}?subject=${value.subject}`}>
                                {children}
                            </a>
                        );
                    case "phone":
                        return (
                            <a className={style.link} href={`tel:${value.link}`}>
                                {children}
                            </a>
                        );
                    default:
                        return (
                            <a className={style.link} href={`${value.link}`} target="_blank" rel="noreferrer">
                                {children}
                            </a>
                        );
                }
            };
            return type();
        },
    },
};

const Privacy = ({ data }) => {
    const {sanityPrivacy} = data
    return (
        <div className={style.privacy}>
            <h1>Privacy Policy</h1>
            <PortableText value={sanityPrivacy._rawBlockPart} components={components}/>
        </div>
    );
}

export default Privacy

export const query = graphql`
    {
        sanityPrivacy {
            _rawBlockPart(resolveReferences: { maxDepth: 10 })
        }
    }
`;